<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom:0px;padding-top:0px;">
        <div class="auth-inner main-container-width">

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card v-if="finished"
                            class="bg-gradient full-min-height">
                        <v-card-text class="text-center confirmation">
                            <v-img contain
                                   max-width="100px"
                                   class="auth-3d-group overflow-hidden mx-auto mb-2"
                                   :src="require(`@/assets/images/login/cong.png`)">
                            </v-img>
                            <br>
                            <p class="text-2xl font-weight-semibold text-black text-center pt-5">
                                {{ $t('Congratulations!') }}
                            </p>

                            <p class="font-weight-semibold mb-5">

                                {{ $t(`You have successfully completed the enrollment process.You can now return to the
                                sign-in page and login to the online services.`) }}
                            </p>

                            <v-btn block
                                   color="primary"
                                   type="button"
                                   @click="backToSignIn"
                                   x-large
                                   class="mt-6 py-3">

                                {{ $t('Return to Sign-in Page') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-row class="mx-lg-5 mx-2">
                            <v-col sm="12"
                                   md="12"
                                   lg="12">
                                <v-card flat
                                        v-if="!finished"
                                        class="full-min-height">
                                    <v-card-text class="pb-0">

                                    </v-card-text>

                                    <v-stepper v-model="step"
                                               class="custom"
                                               style="background: transparent;">
                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <enrollment-step-one @submit="nextStep($event)"
                                                                     @back="backToSignIn()"></enrollment-step-one>
                                                <!-- Card Last Four -->
                                            </v-stepper-content>

                                            <v-stepper-content step="2">
                                                <EnrollmentStepTwo @submit="nextStep($event)"
                                                                   @back="backStep($event)"
                                                                   v-if="step == '2'"></EnrollmentStepTwo>
                                                <!-- OTP and Captcha -->
                                            </v-stepper-content>

                                            <v-stepper-content step="3">
                                                <EnrollmentStepFive @submit="finishedEnrollment($event)"
                                                                    @back="backStep($event)"
                                                                    v-if="step == '3'"></EnrollmentStepFive>
                                                <!-- Set Password -->
                                            </v-stepper-content>

                                        </v-stepper-items>
                                    </v-stepper>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import themeConfig from '@themeConfig'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import router from '@/router'

import store from '@/store'

import EnrollmentStepOne from './EnrollmentStepOne.vue'
import EnrollmentStepTwo from './EnrollmentStepTwo.vue'
import EnrollmentStepFive from './EnrollmentStepFive'

export default {
    components: { AppBarI18n, AppBarThemeSwitcher, EnrollmentStepOne, EnrollmentStepTwo, EnrollmentStepFive },
    data: () => ({
        step: 1,
        finished: false,
        selectedLanguage: 'en'
    }),
    mounted()
    {
        if (this.$route.params.type == 'centurion')
        {
            setTimeout(() => {
                this.$vuetify.theme.dark = true;
            }, 200);
        }
        else
        {
            this.$vuetify.theme.dark = false;
        }

        window.scrollTo(0, 0);
    },
    created()
    {
        store.subscribe(mutation =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    methods: {
        nextStep()
        {
            this.step++;
            window.scrollTo(0, 0);
        },
        backStep()
        {
            this.step--;
            window.scrollTo(0, 0);
        },
        finishedEnrollment()
        {
            this.finished = true;
            window.scrollTo(0, 0);
        },
        backToSignIn()
        {
            if (this.$route.params.type == 'centurion')
            {
                router.push('/' + this.selectedLanguage + '/login-centurion')
            }
            else
            {
                router.push('/' + this.selectedLanguage + '/login')
            }
        }
    },
    setup()
    {
        return {
            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.confirmation {
    width: 40vw;
    margin: auto;
}

@media screen and (max-width: 699px) {
    .text-black {
        font-size: 17px !important;
    }

    .text-color-black {
        font-size: 14px !important;
    }

    .confirmation {
        width: 80vw;
        margin: auto;
    }


}

@media screen and (max-width: 400px) {
    .v-stepper__content {
        padding: 24px 0px !important;
    }
}
</style>
