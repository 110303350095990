<template>
    <div>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>
        </div>

        <v-form ref="loginForm"
                class="multi-col-validation"
                @submit.prevent="saveUserData"
                lazy-validation
                v-model="valid">
            <v-row>
                <v-col cols="12"
                       lg="5"
                       md="6"
                       sm="12">
                    <div>
                        <v-icon class="arabic-arrow"
                                color="green-darken-2"
                                style="font-size: 31px;cursor: pointer;"
                                @click="back">
                            mdi-arrow-left
                        </v-icon>
                        <p class="arabic-font text-lg-2xl text-xl font-weight-semibold text-black pt-5"
                           style="margin-bottom: 0;">
                            {{ $t('Enrollment Step') }} {{ '3' }} {{ $t('of') }} 3

                        </p>
                        <p class="arabic-font font-weight-semibold text-color-black mb-2 mt-5">
                            {{ $t('Please enter the details requested below.') }}
                        </p>
                        <div style="margin: auto;">

                            <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                               style="font-size: 13px;">
                                {{ $t('Enter User ID') }}
                            </p>
                            <v-text-field v-model="userName"
                                          outlined
                                          type="text"
                                          :readonly="existingUserName != ''"
                                          :placeholder="$t('Enter User ID')"
                                          :rules="genericRules"
                                          v-mask="'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'"
                                          hide-details="auto"
                                          class="arabic-font mb-2">
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                            </v-text-field>

                            <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                               style="font-size: 13px;">
                                {{ $t('Enter Password') }}
                            </p>

                            <v-text-field v-model="password"
                                          outlined
                                          :type="isPasswordVisible ? 'text' : 'password'"
                                          v-mask="'XXXXXX'"
                                          :placeholder="$t('Enter Password')"
                                          :append-icon="password ? isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline : ''"
                                          :rules="passwordRules"
                                          hide-details="auto"
                                          class="arabic-font mb-2"
                                          @click:append="isPasswordVisible = !isPasswordVisible">
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                            </v-text-field>

                            <p class="arabic-font font-weight-semibold mb-2"
                               style="font-size: 11px">
                                {{ $t('Password must be 6 characters containing letters, numbers and symbols.') }}
                            </p>
                            <v-card class="security">
                                <v-card-text>
                                    <span class="arabic-font text-color-black">{{ $t('Password strength is') }} </span>

                                    <span class="arabic-font"
                                          v-if="getIndicatorWidth() < 66"
                                          style="color:red;font-weight: 900;">{{ $t('Weak') }}</span>

                                    <span class="arabic-font"
                                          v-if="getIndicatorWidth() == 66"
                                          style="color:#FFA500;font-weight: 900;">{{ $t('Weak') }}</span>

                                    <span class="arabic-font"
                                          v-if="getIndicatorWidth() > 66"
                                          style="color:#048848;font-weight: 900;">{{ $t('Strong') }}</span>

                                    <div class="np-password-strength-indicator-container mt-2">
                                        <div class="np-password-strength-indicator"
                                             :style="{
                                                 backgroundColor: getIndicatorBackgroundColor(),
                                                 width: getIndicatorWidth() + '%',
                                             }"></div>

                                    </div>
                                    <div class="arabic-font mt-5 font-weight-semibold text-color-black mb-3"
                                         style="font-size: 14px;">
                                        {{ $t('Should contain') }}
                                    </div>

                                    <span class="d-flex align-center mt-1">
                                        <img v-if="!uppercase"
                                             src="@/assets/images/cross.png"
                                             style="width: 18px;"
                                             alt="">
                                        <img v-if="uppercase"
                                             src="@/assets/images/tick.png"
                                             style="width: 18px;"
                                             alt="">
                                        <span :class="{ red: uppercase === true, 'text-color-black arabic-font': uppercase === false }"
                                              style="font-size: 13px;margin-left: 10px;margin-right: 10px;">
                                            {{ $t('Uppercase Letter') }}
                                        </span>
                                    </span>
                                    <span class="d-flex align-center mt-1">
                                        <img v-if="!lowercase"
                                             src="@/assets/images/cross.png"
                                             style="width: 18px;"
                                             alt="">
                                        <img v-if="lowercase"
                                             src="@/assets/images/tick.png"
                                             style="width: 18px;"
                                             alt="">
                                        <span :class="{ red: lowercase === true, 'text-color-black arabic-font': lowercase === false }"
                                              style="font-size: 13px;margin-left: 10px;margin-right: 10px;">
                                            {{ $t('Lowercase Letter') }}
                                        </span>
                                    </span>
                                    <span class="d-flex align-center mt-1">
                                        <img v-if="!number"
                                             src="@/assets/images/cross.png"
                                             style="width: 18px;"
                                             alt="">
                                        <img v-if="number"
                                             src="@/assets/images/tick.png"
                                             style="width: 18px;"
                                             alt="">
                                        <span :class="{ red: number === true, 'text-color-black arabic-font': number === false }"
                                              style="font-size: 13px;margin-left: 10px;margin-right: 10px;">
                                            {{ $t('Number') }}
                                        </span>
                                    </span>
                                    <span class="d-flex align-center mt-1">
                                        <img v-if="!character"
                                             src="@/assets/images/cross.png"
                                             style="width: 18px;"
                                             alt="">
                                        <img v-if="character"
                                             src="@/assets/images/tick.png"
                                             style="width: 18px;"
                                             alt="">
                                        <span :class="{ red: character === true, 'text-color-black arabic-font': character === false }"
                                              style="font-size: 13px;margin-left: 10px;margin-right: 10px;">
                                            {{ $t('Character') }}
                                        </span>
                                    </span>

                                </v-card-text>
                            </v-card>

                            <p class="arabic-font font-weight-semibold text-color-black mb-4 mt-4"
                               style="font-size: 13px;">

                                {{ $t('Confirm Password') }}
                            </p>

                            <v-text-field v-model="confirmPassword"
                                          outlined
                                          :type="isCPasswordVisible ? 'text' : 'password'"
                                          :placeholder="$t('Confirm Password')"
                                          v-mask="'XXXXXX'"
                                          :append-icon="confirmPassword ? isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline : ''"
                                          :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                                          hide-details="auto"
                                          class="mb-2 arabic-font"
                                          @click:append="isCPasswordVisible = !isCPasswordVisible">
                                <template #message="{ message }">
                                    {{ $t(message) }}
                                </template>
                            </v-text-field>

                            <v-btn block
                                   color="primary"
                                   type="submit"
                                   :disabled="password.length < 6 || confirmPassword.length < 6 || password != confirmPassword || getIndicatorWidth() < 66"
                                   x-large
                                   class="arabic-font mt-6 py-3 btn-dark">
                                {{ $t('Confirm') }}
                            </v-btn>
                        </div>

                        <TermAndPolicy></TermAndPolicy>
                    </div>
                </v-col>
                <v-col class="divide">
                    <v-divider class="my-2"
                               :vertical="true"></v-divider>
                </v-col>
                <v-col cols="12"
                       lg="6"
                       md="5"
                       sm="12">
                    <div class="text-center"
                         style="padding-top: 38px;">
                        <div class="secure-img">
                            <img src="@/assets/images/logos/account-detail.png"
                                 alt="">
                        </div>
                        <p class="arabic-font font-weight-semibold text-color-black mb-2 mt-5">

                            {{ $t('Account Details') }}
                        </p>
                        <p class="arabic-font font-weight-semibold text--primary mb-2 mt-5 security-text"
                           style="margin: 0 auto;font-size: 13px;">

                            {{ $t('You will use these details to log in to your account from any device.') }}
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline, mdiClose } from '@mdi/js'
import Password from 'vue-password-strength-meter'
import enrollmentServices from '@/services/enrollment-services'
import sharedServices from '@/services/shared-services';
import alert from '../alert.vue';

import store from '@/store';
import valueWraper from '@core/utils/keyValueMaper';

import restErrorMessages from '@/@core/utils/rest-error-messages';

import TermAndPolicy from '../term-and-policy/TermAndPolicy.vue';

import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: { Password, alert, TermAndPolicy },
    data: () => ({
        analytics: getAnalytics(),
        valid: false,
        uppercase: false,
        lowercase: false,
        number: false,
        character: false,
        userName: "",
        existingUserName: '',
        password: "",
        confirmPassword: "",
        inputStrength: 0,
        alertMessage: '',
        alertType: 'error',
        showAlert: false,
        genericRules: [
            v => !!v || 'User ID is required'
        ],
        passwordRules: [
            (value) => !!value || "Password is required.",
            (value) => (value && value.length >= 6) || "password_info",
        ],
        confirmPasswordRules: [
            (value) => !!value || "Confirm password is required",
        ],
        isPasswordVisible: false,
        isCPasswordVisible: false,
        icons: {
            mdiEyeOutline,
            mdiEyeOffOutline,
            // mdiClose
        },
        // selectedLanguage: 'en'
    }),
    mounted()
    {
        window.scrollTo(0, 0);
        this.userName = localStorage.getItem('userName');
        this.existingUserName = localStorage.getItem('userName');
        // this.userName = store.get("requestKeys/userName");
    },
    computed: {
        passwordConfirmationRule()
        {
            return () => this.password === this.confirmPassword || "Confirm Password must match";
        }
    },
    methods: {
        inputContainsNumbers()
        {
            const numbers = /\d/;
            return numbers.test(this.password);
        },
        inputContainsCapitalAlphabet()
        {
            const capitalAlphabet = /^(.*[A-Z].*)$/;
            return capitalAlphabet.test(this.password);
        },
        inputContainsSpecialCharacters()
        {
            const specialCharacters = /[`!@#$%^&*()_+\-=\\|,.<>?~]/;
            return specialCharacters.test(this.password);
        },

        getIndicatorBackgroundColor()
        {

            let color = "gray";
            switch (this.inputStrength)
            {
                case 0:
                    color = "gray";
                    break;
                case 1:
                    color = "red";
                    break;
                case 2:
                    color = "orange";
                    break;
                case 3:
                    color = "green";
                    break;
                default:
                    color = "gray";
            }
            return color;
        },
        getIndicatorWidth()
        {
            if (this.password)
            {
                return parseInt((this.inputStrength / 3) * 100).toString();
            }

        },
        back()
        {
            this.$emit('back');
        },
        submit()
        {
            this.$emit("submit");
        },
        saveUserData()
        {
            if (this.$refs.loginForm.validate())
            {
                let dict = {
                    password: this.password,
                    userName: this.userName
                };

                sharedServices.getToken('enr', this.userName).then(tokenRes =>
                {
                    enrollmentServices.saveUserData({ dict: dict, tokenRes: tokenRes }).then(userDataResp =>
                    {
                        if (userDataResp?.data?.responseInfo?.errorCode == "00000")
                        {

                            logEvent(this.analytics, 'AMEX_CI_SIGNUP', {
                                content_type: 'userName',
                                content_id: userDataResp.data.userName
                            })
                            localStorage.setItem("isUserRegistered", userDataResp.data.isUserRegistered);
                            localStorage.setItem("userName", userDataResp.data.userName);
                            store.set("requestKeys/userName", userDataResp.data.userName);
                            localStorage.setItem("password", this.password);
                            this.submit();
                        }
                        else
                        {
                            if (userDataResp?.data?.responseInfo?.errorCode != "00000")
                            {
                                if (userDataResp?.data?.responseInfo?.errorCode === "403")
                                {
                                    this.alertMessage = restErrorMessages.get(userDataResp?.data?.responseInfo?.errorCode, userDataResp?.data?.responseInfo?.errorDescription);
                                    this.alertType = 'error';
                                    this.showAlert = true;
                                    setTimeout(() =>
                                    {
                                        this.showAlert = false;
                                        window.location.reload();
                                    }, 5000);
                                }
                                else
                                {
                                    this.alertMessage = restErrorMessages.get(userDataResp?.data?.responseInfo?.errorCode, userDataResp?.data?.responseInfo?.errorDescription);
                                    this.alertType = 'error';
                                    this.showAlert = true;
                                    setTimeout(() =>
                                    {
                                        this.showAlert = false;
                                    }, 5000)
                                }
                            }
                            else if (userDataResp?.error?.response?.status != 200)
                            {
                                this.alertMessage = restErrorMessages.get(userDataResp?.data?.responseInfo?.errorCode, userDataResp?.error?.response?.data?.responseInfo?.errorDescription);
                                this.alertType = 'error';
                                this.showAlert = true;
                                setTimeout(() =>
                                {
                                    this.showAlert = false;
                                }, 5000)
                            }
                        }
                    });
                })
            }
        }
    },
    watch: {
        password: function (newVal, oldVal)
        {
            this.inputStrength = 0;
            const inputStrengthLengthCheck = this.password && this.password.length >= 7;
            const inputContainsSpecialCharacters = this.inputContainsSpecialCharacters();
            const inputContainsNumbers = this.inputContainsNumbers();
            const inputContainsCapitalAlphabet = this.inputContainsCapitalAlphabet();

            if (inputStrengthLengthCheck)
            {
                this.inputStrength++;
            }
            if (inputContainsCapitalAlphabet)
            {
                this.inputStrength++;
            }
            if (inputContainsSpecialCharacters)
            {
                this.inputStrength++;
            }
            if (inputContainsNumbers)
            {
                this.inputStrength++;
            }
            const strength = valueWraper.getStrength(this.password);

            this.uppercase = strength.uppercase;
            this.lowercase = strength.lowercase;
            this.number = strength.number;
            this.character = strength.character;

        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.main-width {
    width: 502px;
}

@media screen and (max-width: 630px) {
    .main-width {
        width: 100% !important;
    }

}

.secure-img {
    background: #E2F1FD;
    width: 123px;
    height: 123px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
}

.red {
    color: #048848;
}

.blue {
    color: red
}

.np-password-strength-indicator-container {
    width: 250px;
    height: 11px;
    background: #eee;
    border-radius: 6px;
}

.np-password-strength-indicator {
    width: 250px;
    height: 11px;
    background: #eee;
    border-radius: 6px;
    width: 33%;
    transition: all 0.3s;
}

.np-password-hint {
    margin-top: 10px;
    max-width: 300px;
}

.security-text {
    width: 260px;
}

.divide {
    height: 235px;
    position: relative;
    left: 74px;
    right: 74px;
    top: 48px;
}

@media screen and (max-width: 950px) {

    .divide {
        display: none;
    }
}

@media screen and (max-width: 444px) {
    .np-password-strength-indicator-container {
        width: 153px;
        height: 11px;
        background: #eee;
        border-radius: 6px;
    }

    .np-password-strength-indicator {
        width: 153px;
        height: 11px;
        background: #eee;
        border-radius: 6px;
        width: 33%;
        transition: all 0.3s;
    }
}

@media screen and (max-width: 399px) {

    .text-color-black {
        font-size: 14px !important;
    }

}

@media screen and (max-width: 500px) {
    .security-text {
        width: 100%;
    }

}
</style>
